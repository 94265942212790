<template>
  <div id="dashboard">
    <v-container fluid>
      <v-row dense>
        <v-col :cols="4">
          <v-card>
            <v-img
              :src="'images/fotos/personal2.jpg'"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)"
              >
              <v-card-subtitle class="white--text"
                ><h5>Primavera 2020</h5></v-card-subtitle
              >
            </v-img>
          </v-card>
        </v-col>
        <v-col :cols="8" class="align-center">
          <v-card>
            <v-card-subtitle class="text-center"
              ><h2>ADOLFO BETANCOURT ALCÁNTARA</h2></v-card-subtitle
            >
            <v-card-subtitle class="text-center mt-n8"
              >Director General / Socio Fundador</v-card-subtitle
            >
            <v-row dense class="mt-n4">
              <v-col :cols="12">
                <v-card>
                  <v-card-title class="blue--text"
                    >Información Personal</v-card-title
                  >
                  <v-card-text>
                    <div>
                      <strong>Pasatiempos: </strong>
                      <ul>
                        <li>
                          Futbol, fanático de la Cruz Azul de toda la vida.
                        </li>
                        <li>Amante del café y sus sabores.</li>
                        <li>Cómplice del tequila y sus fiestas.</li>
                        <li>
                          Estudiar la enología como forma de la vida y deleite
                          de la comida.
                        </li>
                        <li>Amo el vino mexicano y si es tinto mejor.</li>
                      </ul>
                    </div>
                    <div>
                      <strong
                        >Una de las experiencias más enriquecedoras en tu
                        vida:</strong
                      >
                    </div>
                    <div>
                      Ser padre y saber que las personas nacidas de mí no son mi
                      responsabilidad sino mi camino al éxito.
                    </div>
                    <div>
                      <strong>Metas:</strong>
                      <ul>
                        <li>Dejar huella en este mundo con una fundación.</li>
                        <li>
                          Crear empresas que ayuden a formar mejores personas.
                        </li>
                        <li>
                          Lograr la libertad económica y seguir disfrutando la
                          vida.
                        </li>
                      </ul>
                    </div>
                    <div>
                      <strong>Frases para la Vida:</strong>
                    </div>
                    <div>
                      Los amigos son como los diamantes, se descubren no se
                      crean.<br />Sólo se puede vivir de una forma: feliz.<br />Si
                      por pendejo mi caigo, por chingo me levanto (dicho
                      mexicano).<br />
                      Siempre estoy feliz, pero no siempre estoy contento.
                    </div>
                    <div>
                      <strong
                        >Te quiero compartir las siguientes memorias:</strong
                      >
                    </div>
                    <div>
                      <ul>
                        <li>Mi primera computadora fue una Commodore 64.</li>
                        <li>
                          Realice un viaje de pasantías en la preparatoria al
                          Centro Espacial Lyndon B, “NASA” en Houston, Texas,
                          por tener uno de los mejores promedios de mi
                          generación.
                        </li>
                        <li>En una época de mi vida jugué boliche de manera organizada y logré estar ranqueado en el lugar número 48 de segunda fuerza, 98 a nivel nacional. </li>
                      </ul>
                    </div>
                    <div>
                      <strong> La clave de tu éxito:</strong>
                      Hacer de mi pasión una forma de vida.
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <div class="text-start">
                <v-btn
                  rounded
                  color="primary white--text"
                  class="mx-5 my-2"
                  to="/myself"
                  >Anterior</v-btn
                >
              </div>
              <v-spacer></v-spacer>
              <div class="text-end">
                <v-btn
                  rounded
                  color="primary white--text"
                  class="mx-5 my-2"
                  to="/goals"
                  >Siguiente</v-btn
                >
              </div></v-row
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import pageFooter from "@/components/Footer.vue";
import header from "@/components/header.vue";
export default {
  components: {
    pageFooter,
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "N°",
          align: "center",
          value: "id",
        },
        {
          text: "Nombre de Puesto",
          align: "center",
          value: "nombrePuesto",
        },
        {
          text: "Editar  /   Ver  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      tab: null,
      text: "inin",
      align: ["start", "end", "center", "baseline", "stretch"],
    };
  },
  methods: {},
  created() {},
};
</script>
